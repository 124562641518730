import * as type from 'constants/action_types'
import * as registrationActionTypes from 'constants/action_types/registrations'

export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  emailStatus: {
    isLoading: false,
    available: true
  },
  password: '',

  address_line1: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  address_country: '',

  paymentMethod: null,

  formValidationErrors: {
    firstName: '',
    lastName: '',
    email: ''
  },

  stripeValidationErrors: {
    firstName: '',
    lastName: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    address_zip: ''
  },

  registrationInfo: {
    purchaseInfo: null,
    combinationType: null,
    membershipType: null,
    extensionType: null
  },

  loadUpsellConfirmation: false,
  loadUpsellTable: false,

  notice: '',
  trialNotice: null,

  initialLoad: {
    isLoading: false,
    completed: false
  },

  register: {
    isLoading: false,
    completed: false,
    processingStage: '' // To give an informative message to a spinner while processing
  },

  saveAptToken: {
    isLoading: false,
    completed: false
  },

  alipay: {
    isLoading: false,
    completed: false,
    errors: []
  },
  retryAlipay: false,

  existingUserCheckout: {
    isLoading: false,
    skipConfirmation: false,
    completed: false
  },

  // onboardingQuestions: {0: ['whyJS']},
  onboardingQuestions: [['whyJS']],

  showMtToggle: false,
  mtToggleMonthly: true,

  registrationProducts: {
    membershipTypes: [],
    extensionTypes: []
  },

  defaultMonthlyMembership: null,
  defaultYearlyMembership: null,
  selectedPlanType: null,
  selectedMembership: null,
  selectedExtension: null,

  showLoginModal: false,
  pendingYearlyUpsell: null,
  googlePlacesLoaded: false,
  isEligible: true,
  additionalUserInfo: {
    firstName: '',
    lastName: '',
    email: '',
    hasMembership: false,
    hasAccount: false,
    accountName: ''
  }
}

function registrations(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_REGISTRATION_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.COMPLETED_REGISTRATION_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: true
        }
      }
    case type.RESET_REGISTRATION_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: false,
          processingStage: ''
        }
      }
    case type.REGISTRATION_INPUT_FIELD_UPDATE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value
      }
    case type.ADD_REGISTRATION_FORM_VALIDATION_ERROR:
      return {
        ...state,
        formValidationErrors: {
          ...state.formValidationErrors,
          [action.payload.field]: action.payload.message
        }
      }
    case type.ADD_ADDRESS_FORM_VALIDATION_ERROR:
      return {
        ...state,
        stripeValidationErrors: {
          ...state.stripeValidationErrors,
          [action.payload.field]: action.payload.message
        }
      }
    case type.ADDRESS_INPUT_FIELD_UPDATE:
      return {
        ...state,
        [action.payload.prop]: action.payload.value
      }
    case type.SET_REGISTRATION_INFO:
      return {
        ...state,
        ...action.payload
      }
    case type.SET_REGISTRATION_UPSELL_INFO: {
      return {
        ...state,
        registrationInfo: {
          ...state.registrationInfo,
          ...action.payload
        }
      }
    }
    case type.SET_REGISTRATION_EMAIL_STATUS:
      return {
        ...state,
        emailStatus: {
          ...state.emailStatus,
          ...action.payload
        }
      }
    case type.CLEAR_REGISTRATION_INFO:
      return {
        ...state,
        registrationInfo: initialState.registrationInfo
      }
    case type.TOGGLE_UPSELL_CONFIRMATION:
      return {
        ...state,
        pendingYearlyUpsell: action.payload
      }
    case type.LOAD_UPSELL_CONFIRMATION:
      return {
        ...state,
        loadUpsellConfirmation: true
      }
    case type.LOAD_UPSELL_TABLE:
      return {
        ...state,
        loadUpsellTable: true
      }
    case type.SELECT_UPGRADE_OPTION:
      return {
        ...state,
        loadUpsellConfirmation: true,
        registrationInfo: {
          ...state.registrationInfo,
          upgradeTo: action.payload
        }
      }
    case type.UPDATE_ELIGIBLE:
      return {
        ...state,
        isEligible: action.payload
      }
    case type.PENDING_EXISTING_USER_CHECKOUT_ACTION_WITH_NEW_PAYMENT:
      return {
        ...state,
        existingUserCheckout: {
          isLoading: true,
          skipConfirmation: true
        }
      }
    case type.PENDING_EXISTING_USER_CHECKOUT_ACTION:
      return {
        ...state,
        existingUserCheckout: {
          isLoading: true,
          skipConfirmation: false
        }
      }
    case type.RESET_EXISTING_USER_CHECKOUT_ACTION:
      return {
        ...state,
        existingUserCheckout: {
          isLoading: false,
          skipConfirmation: state.existingUserCheckout.skipConfirmation
        }
      }
    case type.SET_RETRY_ALIPAY:
      return {
        ...state,
        retryAlipay: action.payload
      }
    case registrationActionTypes.SET_REGISTRATION_PROCESSING_STAGE:
      return {
        ...state,
        register: {
          ...state.register,
          processingStage: action.payload.value
        }
      }
    case registrationActionTypes.SET_ADDITIONAL_USER_INFO:
      return {
        ...state,
        additionalUserInfo: { ...action.payload },
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email
      }
    default:
      return state
  }
}

export default registrations
